// Components
import { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import Footer from '../components/footer';
import Unverified from '../components/unverified';

// Utilities
import { IsDesktop } from '../utilities/mediaQuery';
import moment from 'moment';
import { actionCodeSettings, auth } from '../utilities/firebaseController';
import { useAuthState } from 'react-firebase-hooks/auth';
import axios from 'axios';
import {
  signInWithEmailAndPassword,
  updatePassword,
  sendEmailVerification,
  deleteUser,
} from 'firebase/auth';
import { WasteTypes } from '../utilities/wasteTypes';
import { RouteCycles } from '../utilities/routeCycles';

// Colors
import { amber, green, grey, red } from '@mui/material/colors';
import {
  accent900,
  accent800,
  secondary500,
  secondary900,
  primary500,
} from '../utilities/colors';

// Icons
import ProfileIcon from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/ExpandMore';
import LessIcon from '@mui/icons-material/ExpandLess';
import Unregistered from '../components/unregistered';
import DownloadIcon from '@mui/icons-material/Download';
import ImageIcon from '@mui/icons-material/Image';
import DocumentIcon from '@mui/icons-material/Article';
import { history } from '../utilities/history';

const binSafetyDocs = [
  {
    name: 'Bin Safety Document',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FbinSafetyDocs%2FBin%20Safety%20Doc%20-%20Waster.pdf?alt=media&token=f5595e87-f3fd-43a2-8c91-9f91d9b2ebb7',
    type: 'PDF',
  },
  {
    name: 'Bin Safety Procedures',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FbinSafetyDocs%2FBin%20Safety%20Procedures.pdf?alt=media&token=a0c1d2d5-e4d2-428c-9d91-2d19551781f2',
    type: 'PDF',
  },
  {
    name: 'Front Lift Bins',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FbinSafetyDocs%2FFL%20Bins.pdf?alt=media&token=2fdbd7d3-151a-49cd-98d1-e9baed0c6a59',
    type: 'PDF',
  },
  {
    name: 'Rear Lift Bins',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FbinSafetyDocs%2FRL%20Bins.pdf?alt=media&token=cd03a357-86dd-4191-8d44-80d2a6ae03f9',
    type: 'PDF',
  },
];
const recyclingFlowcharts = [
  {
    name: 'Aged Care Recycling Flowchart',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FrecyclingFlowcharts%2FAged-Care-Recycling-Flowchart.pdf?alt=media&token=5b9eba2a-5525-4a4c-8ca8-1b7b3c63dcd6',
    type: 'PDF',
  },
  {
    name: 'Cafe Recycling Flowchart',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FrecyclingFlowcharts%2FCafe-recycling-flowchart-Waster.pdf?alt=media&token=8497d335-1fa9-473b-802d-148d90a1a2eb',
    type: 'PDF',
  },
  {
    name: 'Child Care Recycling Flowchart',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FrecyclingFlowcharts%2FChild-care-centre-recycling-flowchart.pdf?alt=media&token=cb67e163-4560-4acd-8136-766054451dd1',
    type: 'PDF',
  },
  {
    name: 'Dentist Surgery Recycling Flowchart',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FrecyclingFlowcharts%2FDentist-surgery-recycling-flowchart.pdf?alt=media&token=d1905cb4-110c-4df2-9f4d-7db6a491b82b',
    type: 'PDF',
  },
  {
    name: 'Gym or Dance School Recycling Flowchart',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FrecyclingFlowcharts%2FGym-Or-Dance-school-recycling-flowchart.pdf?alt=media&token=20593ead-3db0-4c05-bb4a-96373dbac2bd',
    type: 'PDF',
  },
  {
    name: 'Health and Beauty Recycling Flowchart',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FrecyclingFlowcharts%2Fhealth-and-beauty-recycling-australia.pdf?alt=media&token=f561941d-2bf2-4189-91e9-e3c81899c079',
    type: 'PDF',
  },
  {
    name: 'Medical Practise Recycling Flowchart',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FrecyclingFlowcharts%2FMedical-practise-recycling-flowchart.pdf?alt=media&token=c8340bdb-e26b-43d9-8378-40f99ac7df94',
    type: 'PDF',
  },
  {
    name: 'Retail Shops Recycling Flowchart',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FrecyclingFlowcharts%2FRETAIL-SHOPS-RECYCLING-FLOWCHART-WASTER.pdf?alt=media&token=77afff11-7a50-4651-b420-47efcb502fd3',
    type: 'PDF',
  },
  {
    name: 'Restaurant Recycling Flowchart',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FrecyclingFlowcharts%2FRestaurant-Recycling-Flowchart.pdf?alt=media&token=1466a905-7450-49f8-931f-e2966d5eda04',
    type: 'PDF',
  },
  {
    name: 'Veterinary Practise Recycling Flowchart',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FrecyclingFlowcharts%2FVeterinary-practise-recycling-flowchart.pdf?alt=media&token=46fb8e57-0fa7-4e52-8edf-b4059a272d8e',
    type: 'PDF',
  },
  {
    name: 'Warehouse Recycling Flowchart',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FrecyclingFlowcharts%2FWarehouse-recycling-flowchart-Waster.pdf?alt=media&token=e5435378-7918-4264-9979-ce1c87dabe9f',
    type: 'PDF',
  },
  {
    name: 'Office Recycling Flowchart',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FrecyclingFlowcharts%2FWaster-Office-recycling-flowchart.pdf?alt=media&token=97a84dd9-e853-452a-bd0e-b453b735198e',
    type: 'PDF',
  },
];
const binPostersAndStickers = [
  {
    name: 'Cardboard And Paper',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FbinPostersAndStickers%2FCardboard-and-paper-bin-sticker.png?alt=media&token=f51df1e2-f757-400a-bd04-e79683030e5b',
    type: 'IMG',
  },
  {
    name: 'Commingled/Mixed Recycling',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FbinPostersAndStickers%2FCommingled-recycling-mixed-recycling-bin-sticker.png?alt=media&token=fcddf9bd-2c24-48d1-bcb1-25b9eb3074ef',
    type: 'IMG',
  },
  {
    name: 'General Waste',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FbinPostersAndStickers%2FGeneral-Waste-bin-sticker.png?alt=media&token=73604e3f-1188-4e58-bc97-6b71f5b05eac',
    type: 'IMG',
  },
  {
    name: 'Medical/Clinical Waste',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FbinPostersAndStickers%2FMedical-Waste-Bin-Service-Clinical.jpg?alt=media&token=c9850721-81ea-4bbc-a306-460ba10bba40',
    type: 'IMG',
  },
  {
    name: 'Organic/Food Waste',
    link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FbinPostersAndStickers%2FOrganic-Waste-Bin-Service-Food-Waste.jpg?alt=media&token=4040635b-ac93-4cc9-8622-6b228218c943',
    type: 'IMG',
  },
];

const termsAndConditions = {
  name: 'Terms and Conditions',
  link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2FtermsAndConditions%2FWASTER-PTY-LTD-Terms-And-Conditions-Feb-2022.pdf?alt=media&token=9981c1c8-d18f-428e-a00d-222616ad8315',
  type: 'PDF',
};

const wasterAPPTutorial = {
  name: 'WasterAPP Tutorial',
  link: 'https://firebasestorage.googleapis.com/v0/b/wasterapp-9ea2a.appspot.com/o/GenDoc%2Ftutorial%2FWASTER-PTY-LTD-WasterAPP-Tutorial-Oct-2022.pdf?alt=media&token=45b139a6-dec9-4ea4-9a61-9806276a4ef8',
  type: 'PDF',
};

function getAccountState(state) {
  var status = 'Unknown';
  var statusColor = grey[500];
  if (state === null) {
    status = 'Active';
    statusColor = green[500];
  } else if (state === 'T') {
    status = 'Terminated';
    statusColor = red[500];
  } else if (state === 'L') {
    status = 'Legal';
    statusColor = amber[500];
  } else if (state === 'C') {
    status = 'Credit Hold';
    statusColor = amber[500];
  }
  return {
    status: status,
    statusColor: statusColor,
  };
}

// Assets
export default function Profile() {
  // STYLES
  // Generic
  var isDesktop = IsDesktop();
  // Specific
  const mainContainer = {
    px: isDesktop ? '15px' : 0,
    gap: '15px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  };

  // VARIABLES
  const [user, loading, error] = useAuthState(auth);
  const [userVerified, setUserVerified] = useState(null);
  const [wastedgeDetails, setWastedgeDetails] = useState(null);
  const [wastedgeServices, setWastedgeServices] = useState(null);
  const [wastedgeDocs, setWastedgeDocs] = useState(null);

  const [accounts, setAccounts] = useState([]);
  const [accountChosen, setAccountChosen] = useState(null);
  const [noServices, setNoServices] = useState(false);
  const [accountsPaneState, setAccountsPaneState] = useState(false);
  const [accountAddress, setAccountAddress] = useState(null);

  const [securityPaneState, setSecurityPaneState] = useState(false);

  const [changePasswordOld, setChangePasswordOld] = useState(null);
  const [changePasswordNew, setChangePasswordNew] = useState(null);
  const [changePasswordRepeat, setChangePasswordRepeat] = useState(null);

  const [changePasswordOldError, setChangePasswordOldError] = useState(false);
  const [changePasswordNewError, setChangePasswordNewError] = useState(false);
  const [changePasswordRepeatError, setChangePasswordRepeatError] =
    useState(false);

  const [changePasswordOldErrorMessage, setChangePasswordOldErrorMessage] =
    useState(null);
  const [changePasswordNewErrorMessage, setChangePasswordNewErrorMessage] =
    useState(null);
  const [
    changePasswordRepeatErrorMessage,
    setChangePasswordRepeatErrorMessage,
  ] = useState(null);

  const [deleteAccountState, setDeleteAccountState] = useState(false);
  const [deleteAccountConfirmation, setDeleteAccountConfirmation] =
    useState(null);
  const [deleteAccountConfirmationError, setDeleteAccountConfirmationError] =
    useState(false);
  const [
    deleteAccountConfirmationErrorText,
    setDeleteAccountConfirmationErrorText,
  ] = useState(null);

  // FUNCTIONS
  function clearChangePasswordFields() {
    document.getElementById('changePasswordForm').reset();
  }
  const changePasswordOldChange = (e) => {
    let changePasswordOldTemp = e.target.value;
    if (changePasswordOldTemp.length < 1) {
      setChangePasswordOldError(true);
      setChangePasswordOldErrorMessage('Please enter your old password');
    } else {
      setChangePasswordOldError(false);
      setChangePasswordOldErrorMessage(null);
    }
    setChangePasswordOld(changePasswordOldTemp);
  };
  const changePasswordNewChange = (e) => {
    let changePasswordNewTemp = e.target.value;
    if (changePasswordNewTemp.length < 1) {
      setChangePasswordNewError(true);
      setChangePasswordNewErrorMessage('Please enter a new password');
    } else if (changePasswordNewTemp.length < 6) {
      setChangePasswordNewError(true);
      setChangePasswordNewErrorMessage(
        `Password can't be less than 6 characters`
      );
    } else {
      setChangePasswordNewError(false);
      setChangePasswordNewErrorMessage(null);
    }
    setChangePasswordNew(changePasswordNewTemp);
  };
  const changePasswordRepeatChange = (e) => {
    let changePasswordRepeatTemp = e.target.value;
    if (changePasswordRepeatTemp.length < 1) {
      setChangePasswordRepeatError(true);
      setChangePasswordRepeatErrorMessage('Please repeat new password');
    } else if (changePasswordRepeatTemp.length < 6) {
      setChangePasswordRepeatError(true);
      setChangePasswordRepeatErrorMessage(
        `Password can't be less than 6 characters`
      );
    } else if (changePasswordRepeatTemp !== changePasswordNew) {
      setChangePasswordRepeatError(true);
      setChangePasswordRepeatErrorMessage('Password values do not match');
    } else {
      setChangePasswordRepeatError(false);
      setChangePasswordRepeatErrorMessage(null);
    }
    setChangePasswordRepeat(changePasswordRepeatTemp);
  };
  const deleteAccountConfirmationChange = (e) => {
    let deleteAccountConfirmationTemp = e.target.value;
    if (deleteAccountConfirmationTemp.length > 0) {
      setDeleteAccountConfirmationError(false);
      setDeleteAccountConfirmationErrorText(null);
    } else if (deleteAccountConfirmationTemp.length < 1) {
      setDeleteAccountConfirmationError(true);
      setDeleteAccountConfirmationErrorText('Please enter your password');
    }
    setDeleteAccountConfirmation(deleteAccountConfirmationTemp);
  };
  const getDetails = (email) => {
    axios
      .get(
        `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/wastedge/getDetails/${email}`
      )
      .then((response) => {
        setWastedgeDetails(response.data.result);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500) {
          setTimeout(() => {
            getDetails(email);
          }, 6000);
        }
      });
  };
  const getServices = (id) => {
    axios
      .get(
        `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/wastedge/getServices/${id}`
      )
      .then((response) => {
        setWastedgeServices(response.data.result);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500) {
          setTimeout(() => {
            getServices(id);
          }, 6000);
        }
      });
  };
  const changePassword = (e) => {
    e.preventDefault();
    if (!changePasswordNew || !changePasswordRepeat) return;
    if (changePasswordNew !== changePasswordRepeat) return;
    signInWithEmailAndPassword(auth, user.email, changePasswordOld)
      .then(() => {
        updatePassword(auth.currentUser, changePasswordNew)
          .then(() => {
            console.log('Password changed!');
            clearChangePasswordFields();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteAccount = (e) => {
    e.preventDefault();
    console.log(auth.currentUser);
    console.log(deleteAccountConfirmation);
    signInWithEmailAndPassword(auth, user.email, deleteAccountConfirmation)
      .then(() => {
        deleteUser(auth.currentUser)
          .then(() => {
            console.log('Account deleted!');
            history.push('home');
            history.go(0);
          })
          .catch((error) => {
            console.log('Failed to delete account!');
            console.log(error);
          });
      })
      .catch((error) => {
        setDeleteAccountConfirmation(null);
        setDeleteAccountConfirmationError(true);
        setDeleteAccountConfirmationErrorText('Password Incorrect');
        console.log(error);
      });
  };
  function getRADoc(id) {
    setWastedgeDocs(null);
    const urlGetRADoc = `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/riskAssessmentDoc/${id}`;
    axios
      .get(urlGetRADoc)
      .then((response) => {
        setWastedgeDocs(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // EFFECTS
  useEffect(() => {
    if (user) {
      setUserVerified(user.emailVerified);
    }
  }, [user, loading, error]);
  useEffect(() => {
    if (userVerified) {
      getDetails(user.email);
    }
  }, [userVerified]);
  useEffect(() => {
    if (wastedgeDetails) {
      let accountsTemp = [];
      if (wastedgeDetails.length < 1) {
        setNoServices(true);
      } else {
        wastedgeDetails.map((wastedgeDetail) =>
          accountsTemp.push(wastedgeDetail)
        );
        setAccounts(accountsTemp);
      }
    }
  }, [wastedgeDetails]);
  useEffect(() => {
    if (accounts.length > 0) {
      setAccountChosen(accounts[0]);
    }
  }, [accounts]);

  useEffect(() => {
    if (accountChosen) {
      setWastedgeServices(null);
      getServices(accountChosen.$id);
      setAccountAddress(
        `${accountChosen.address_1 ? `${accountChosen.address_1}, ` : ''}${
          accountChosen.address_2 ? `${accountChosen.address_2}, ` : ''
        }${accountChosen.city ? `${accountChosen.city}, ` : ''}${
          accountChosen.state ? `${accountChosen.state}, ` : ''
        }${accountChosen.zip ? `${accountChosen.zip}, ` : ''}${
          accountChosen.country_code ? `${accountChosen.country_code}, ` : ''
        }`
      );
      getRADoc(accountChosen.$id);
    }
  }, [accountChosen]);

  return (
    <Box sx={mainContainer}>
      <Box
        sx={{
          background: grey[300],
          borderBottomLeftRadius: '30px',
          borderBottomRightRadius: '30px',
          boxShadow: `0 1px 2px ${grey[900]}`,
        }}
      >
        <Container
          sx={{
            p: '15px',
            background: grey[100],
            borderBottomLeftRadius: '30px',
            borderBottomRightRadius: '30px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ProfileIcon sx={{ fontSize: '3rem', color: accent900 }} />
            <Box sx={{ ml: '15px' }}>
              <Typography variant="h5" sx={{ color: accent900 }}>
                Your Profile
              </Typography>
              <Typography variant="caption" sx={{ color: grey[700] }}>
                View your account details and settings.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          gap: '15px',
          flexGrow: 1,
          background: userVerified !== null ? grey[300] : null,
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {!user && !loading && !error && <Unregistered />}
        {userVerified === false && <Unverified />}
        {noServices && (
          <Box
            sx={{
              gap: '30px',
              minHeight: '30vh',
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: grey[600],
            }}
          >
            <Typography variant="h4">
              Your email is not found in any Waster services.
            </Typography>
            <Typography
              variant="body2"
              align="center"
              sx={{ color: accent900 }}
            >
              Please make sure that you are using the same email you registered
              for your Waster services.
              <br></br>
              If you need assistance with this, please&nbsp;
              <Link
                onClick={() => {
                  history.push('/contact');
                  history.go(0);
                }}
              >
                contact us
              </Link>
              .
            </Typography>
          </Box>
        )}
        {userVerified && (
          <Box
            sx={{
              p: isDesktop ? '15px' : 0,
              background: grey[300],
              borderRadius: '5px',
              flexGrow: 1,
              display: 'flex',
            }}
          >
            <Container
              sx={{
                p: '15px',
                gap: '15px',
                background: grey[200],
                borderRadius: '5px',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {!accountChosen && !noServices && (
                <Box
                  sx={{
                    minHeight: '30vh',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: grey[400],
                  }}
                >
                  <Typography variant="h4">Loading...</Typography>
                </Box>
              )}
              {accounts.length > 1 && accountChosen && !noServices && (
                <Box
                  sx={{
                    background: secondary500,
                    color: grey[50],
                    borderRadius: `5px`,
                    boxShadow: `0 1px 2px ${grey[900]}`,
                  }}
                >
                  <Box
                    sx={{
                      p: `15px`,
                      display: `flex`,
                      justifyContent: `space-between`,
                      alignItems: `flex-end`,
                    }}
                  >
                    <Box>
                      <Typography variant="caption">
                        Current account:
                      </Typography>
                      <Typography variant="h5">{accountChosen.name}</Typography>
                    </Box>
                    <Button
                      sx={{
                        flexShrink: 0,
                        color: grey[50],
                        '&:hover': {
                          color: grey[100],
                        },
                      }}
                      endIcon={accountsPaneState ? <LessIcon /> : <MoreIcon />}
                      onClick={() => {
                        setAccountsPaneState(!accountsPaneState);
                      }}
                      size="small"
                    >
                      {isDesktop ? 'Choose Account' : null}
                    </Button>
                  </Box>
                  <Collapse in={accountsPaneState}>
                    <Box
                      sx={{
                        background: secondary900,
                        borderRadius: `5px`,
                      }}
                    >
                      {accounts.map((account, index) => (
                        <Box key={index}>
                          <Typography
                            sx={{
                              p: `15px`,
                            }}
                            onClick={() => {
                              setAccountChosen(accounts[index]);
                              setAccountsPaneState(false);
                            }}
                          >
                            {account.name}
                          </Typography>
                          {index < accounts.length - 1 ? (
                            <Divider sx={{ borderColor: grey[500] }} />
                          ) : null}
                        </Box>
                      ))}
                    </Box>
                  </Collapse>
                </Box>
              )}
              {accountChosen && !noServices && (
                <Box
                  sx={{
                    p: isDesktop ? '30px' : '15px',
                    gap: '15px',
                    background: grey[100],
                    borderRadius: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography>Account Details:</Typography>
                  <Box
                    sx={{
                      p: '15px',
                      background: grey[50],
                      borderRadius: '5px',
                      boxShadow: `0 1px 2px ${grey[900]}`,
                      gap: '15px',
                      display: 'flex',
                      flexDirection: 'column',
                      '&> .MuiBox-root': {
                        display: 'flex',
                        flexDirection: isDesktop ? 'row' : 'column',
                        alignItems: 'stretch',
                        '&> .MuiTypography-body2': {
                          py: '10px',
                          px: '15px',
                          width: isDesktop ? '15ch' : 'auto',
                          flexShrink: 0,
                          textAlign: isDesktop ? 'right' : 'left',
                          background: grey[200],
                          color: grey[600],
                          borderTopLeftRadius: '5px',
                          borderTopRightRadius: isDesktop ? 0 : '5px',
                          borderBottomLeftRadius: isDesktop ? '5px' : 0,
                        },
                        '&> .MuiTypography-body1': {
                          py: '5px',
                          px: '15px',
                          borderColor: grey[200],
                          borderStyle: 'solid',
                          borderWidth: '5px',
                          flexGrow: 1,
                          boxSizing: 'border-box',
                          borderTopRightRadius: isDesktop ? '5px' : 0,
                          borderBottomRightRadius: '5px',
                          borderBottomLeftRadius: isDesktop ? 0 : '5px',
                        },
                      },
                    }}
                  >
                    <Box>
                      <Typography variant="body2" noWrap>
                        Email:
                      </Typography>
                      <Typography variant="body1">{user.email}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" noWrap>
                        Company:
                      </Typography>
                      <Typography variant="body1">
                        {accountChosen.name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" noWrap>
                        Account ID:
                      </Typography>
                      <Typography variant="body1">
                        {accountChosen.$id}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" noWrap>
                        State:
                      </Typography>
                      <Typography
                        sx={{
                          color: getAccountState(accountChosen.cut_off_swt)
                            .statusColor,
                          fontWeight: 500,
                        }}
                      >
                        {getAccountState(accountChosen.cut_off_swt).status}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" noWrap>
                        Address:
                      </Typography>
                      <Box
                        sx={{
                          py: '5px',
                          px: '15px',
                          borderColor: grey[200],
                          borderStyle: 'solid',
                          borderWidth: '5px',
                          flexGrow: 1,
                          boxSizing: 'border-box',
                          borderTopRightRadius: isDesktop ? '5px' : 0,
                          borderBottomRightRadius: '5px',
                          borderBottomLeftRadius: isDesktop ? 0 : '5px',
                          gap: '15px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography variant="body1">
                          {accountAddress}
                        </Typography>
                        <iframe
                          title={`Address on Google Maps`}
                          style={{
                            maxWidth: '100%',
                            minHeight: '500px',
                            borderRadius: '5px',
                            flexGrow: 1,
                          }}
                          src={`https://maps.google.com/maps?q=${accountAddress}&t=&z=18&ie=UTF8&iwloc=&output=embed`}
                          frameborder="0"
                          scrolling="no"
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="body2" noWrap>
                        Last updated:
                      </Typography>
                      <Typography variant="body1">{`${moment(
                        accountChosen.update_timestamp
                      ).format('LL')} (${moment(
                        accountChosen.update_timestamp
                      ).fromNow()})`}</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box
                sx={{
                  p: isDesktop ? '30px' : '15px',
                  gap: '15px',
                  background: grey[100],
                  borderRadius: '5px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography>Security Details:</Typography>
                  <IconButton
                    onClick={() => {
                      setSecurityPaneState(!securityPaneState);
                    }}
                    size="small"
                  >
                    {securityPaneState ? <LessIcon /> : <MoreIcon />}
                  </IconButton>
                </Box>
                <Collapse in={securityPaneState}>
                  {!user && (
                    <Box
                      sx={{
                        minHeight: '30vh',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: grey[400],
                      }}
                    >
                      <Typography variant="h4">Loading...</Typography>
                    </Box>
                  )}
                  {user && (
                    <Box
                      sx={{
                        gap: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box
                        sx={{
                          p: '15px',
                          background: grey[50],
                          borderRadius: '5px',
                          boxShadow: `0 1px 2px ${grey[900]}`,
                          gap: '15px',
                          display: 'flex',
                          flexDirection: 'column',
                          '&> .MuiBox-root': {
                            display: 'flex',
                            flexDirection: isDesktop ? 'row' : 'column',
                            alignItems: 'stretch',
                            '&>:first-child': {
                              py: '10px',
                              px: '15px',
                              width: isDesktop ? '15ch' : 'auto',
                              flexShrink: 0,
                              textAlign: isDesktop ? 'right' : 'left',
                              background: grey[200],
                              color: grey[600],
                              borderTopLeftRadius: '5px',
                              borderBottomLeftRadius: '5px',
                            },
                            '&>:last-child': {
                              py: '5px',
                              px: '15px',
                              borderColor: grey[200],
                              borderStyle: 'solid',
                              borderWidth: '5px',
                              flexGrow: 1,
                              boxSizing: 'border-box',
                            },
                          },
                        }}
                      >
                        <Box>
                          <Typography noWrap>Account created:</Typography>
                          <Typography>
                            {moment(user.metadata.creationTime).format('LLLL')}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography noWrap>Last log in:</Typography>
                          <Typography>
                            {moment(user.metadata.lastSignInTime).format(
                              'LLLL'
                            )}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography noWrap>Email verified:</Typography>
                          <Typography
                            sx={{
                              color: userVerified ? green[500] : red[500],
                              fontWeight: 500,
                            }}
                          >
                            {userVerified ? 'Yes' : 'No'}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography noWrap>Change password:</Typography>
                          <Box>
                            <Box
                              id="changePasswordForm"
                              component="form"
                              onSubmit={changePassword}
                              sx={{
                                py: '10px',
                                gap: '15px',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {!userVerified && (
                                <Box
                                  sx={{
                                    p: '15px',
                                    background: accent800,
                                    color: grey[50],
                                    borderRadius: '5px',
                                  }}
                                >
                                  <Typography>
                                    Your account must be verified to change the
                                    password.
                                  </Typography>
                                </Box>
                              )}
                              <TextField
                                required
                                fullWidth
                                disabled={!userVerified}
                                size="small"
                                type="password"
                                label="Old Password"
                                value={changePasswordOld}
                                onChange={changePasswordOldChange}
                                error={changePasswordOldError}
                                helperText={
                                  changePasswordOldError
                                    ? changePasswordOldErrorMessage
                                    : null
                                }
                              />
                              <TextField
                                required
                                fullWidth
                                disabled={!userVerified}
                                size="small"
                                type="password"
                                label="New Password"
                                value={changePasswordNew}
                                onChange={changePasswordNewChange}
                                error={changePasswordNewError}
                                helperText={
                                  changePasswordNewError
                                    ? changePasswordNewErrorMessage
                                    : null
                                }
                              />
                              <TextField
                                required
                                fullWidth
                                disabled={!userVerified}
                                size="small"
                                type="password"
                                label="Repeat Password"
                                value={changePasswordRepeat}
                                onChange={changePasswordRepeatChange}
                                error={changePasswordRepeatError}
                                helperText={
                                  changePasswordRepeatError
                                    ? changePasswordRepeatErrorMessage
                                    : null
                                }
                              />
                              <Button
                                type="submit"
                                disabled={
                                  changePasswordNewError ||
                                  changePasswordRepeatError ||
                                  !userVerified
                                }
                                sx={{
                                  width: 'fit-content',
                                  background: accent800,
                                  color: grey[50],
                                  '&:hover': {
                                    background: accent900,
                                  },
                                  '&.Mui-disabled': {
                                    background: grey[300],
                                    color: grey[500],
                                  },
                                }}
                              >
                                Confirm
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        <Button
                          onClick={() => {
                            setDeleteAccountState(!deleteAccountState);
                          }}
                          sx={{
                            color: grey[50],
                            background: accent800,
                            boxShadow: `0 1px 2px ${grey[900]}`,
                            ':hover': {
                              background: accent900,
                            },
                            borderBottomLeftRadius: deleteAccountState
                              ? 0
                              : null,
                            borderBottomRightRadius: deleteAccountState
                              ? 0
                              : null,
                          }}
                          fullWidth
                        >
                          {deleteAccountState ? 'Cancel' : 'Delete account'}
                        </Button>
                        <Collapse in={deleteAccountState}>
                          <Box
                            component="form"
                            onSubmit={deleteAccount}
                            autoComplete="off"
                            sx={{
                              p: '15px',
                              gap: '15px',
                              background: grey[50],
                              borderBottomLeftRadius: '5px',
                              borderBottomRightRadius: '5px',
                              boxShadow: `0 1px 2px ${grey[900]}`,
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Box>
                              <Typography>
                                Provide password to confirm deletion.
                              </Typography>
                              <Typography variant="caption">
                                This process is irreversible. If you wish to
                                use&nbsp;
                                <span
                                  style={{ color: primary500, fontWeight: 500 }}
                                >
                                  Waster
                                </span>
                                <span
                                  style={{ color: accent800, fontWeight: 500 }}
                                >
                                  APP
                                </span>
                                &nbsp;after deleting your account, you will need
                                to register again. If you have any concerns,
                                please&nbsp;
                                <Link
                                  onClick={() => {
                                    history.push('contact');
                                    history.go(0);
                                  }}
                                  sx={{
                                    fontWeight: 500,
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                  }}
                                >
                                  contact us
                                </Link>
                                .
                              </Typography>
                            </Box>
                            <TextField
                              required
                              fullWidth
                              autoComplete="off"
                              size="small"
                              type="password"
                              label="Password"
                              value={deleteAccountConfirmation}
                              error={deleteAccountConfirmationError}
                              onChange={deleteAccountConfirmationChange}
                              helperText={
                                deleteAccountConfirmationError
                                  ? deleteAccountConfirmationErrorText
                                  : null
                              }
                            />
                            <Button
                              type="submit"
                              disabled={deleteAccountConfirmationError}
                              sx={{
                                color: accent800,
                                ':hover': {
                                  accent900,
                                },
                              }}
                            >
                              Delete Account
                            </Button>
                          </Box>
                        </Collapse>
                      </Box>
                    </Box>
                  )}
                </Collapse>
              </Box>
              {!noServices && (
                <Box
                  sx={{
                    p: isDesktop ? '30px' : '15px',
                    gap: '15px',
                    background: grey[100],
                    borderRadius: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography>Waster Services Details:</Typography>

                  {!wastedgeServices && (
                    <Box
                      sx={{
                        minHeight: '30vh',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: grey[400],
                      }}
                    >
                      <Typography variant="h4">Loading services...</Typography>
                    </Box>
                  )}

                  {wastedgeServices && (
                    <Grid
                      container
                      sx={{
                        gap: '15px',
                        '&> .MuiGrid-item': {
                          p: '15px',
                          gap: '15px',
                          minWidth: 'max-content',
                          background: grey[50],
                          borderRadius: '5px',
                          boxShadow: `0 1px 2px ${grey[900]}`,
                          display: 'flex',
                          flexDirection: 'column',
                          '&>:first-child': {
                            gap: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            '&>:first-child': {
                              width: '48px',
                              height: '48px',
                              borderRadius: '5px',
                            },
                          },
                          '&>:last-child': {
                            p: '15px',
                            gap: '15px',
                            background: grey[200],
                            borderRadius: '5px',
                            display: 'flex',
                            flexDirection: 'column',
                            '&> .MuiBox-root': {
                              gap: '15px',
                              display: 'flex',
                              alignItems: 'center',
                              '&> .MuiTypography-body2': {
                                width: '18ch',
                                textAlign: 'right',
                                fontWeight: 500,
                              },
                            },
                          },
                        },
                      }}
                    >
                      {wastedgeServices.map((wastedgeService) => {
                        if (
                          wastedgeService.waste_type !== 'FUT' &&
                          wastedgeService.waste_type !== 'FUT1' &&
                          wastedgeService.waste_type !== 'FUT2' &&
                          wastedgeService.waste_type !== 'FUT3' &&
                          wastedgeService.waste_type !== 'FUT4'
                        ) {
                          return (
                            <Grid key={wastedgeService.waste_type} item xs>
                              <Box>
                                <Box
                                  sx={{
                                    background: `repeating-linear-gradient(45deg, ${
                                      WasteTypes(wastedgeService.waste_type)
                                        .primaryColor
                                    } 0px, ${
                                      WasteTypes(wastedgeService.waste_type)
                                        .primaryColor
                                    } 15px, ${
                                      WasteTypes(wastedgeService.waste_type)
                                        .secondaryColor
                                    } 15px, ${
                                      WasteTypes(wastedgeService.waste_type)
                                        .secondaryColor
                                    } 30px)`,
                                  }}
                                />
                                <Box>
                                  <Typography variant="caption">
                                    Line{' '}
                                    {`${wastedgeService.$id.split('|')[1]} : ${
                                      WasteTypes(wastedgeService.waste_type)
                                        .code
                                    }`}
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    sx={{
                                      color: WasteTypes(
                                        wastedgeService.waste_type
                                      ).textColor,
                                    }}
                                  >
                                    {
                                      WasteTypes(wastedgeService.waste_type)
                                        .name
                                    }
                                  </Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Box>
                                  <Typography noWrap variant="body2">
                                    Contract Start Date:
                                  </Typography>
                                  <Typography>
                                    {moment(
                                      wastedgeService.contract_str_date
                                    ).format('LL')}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography noWrap variant="body2">
                                    First Service Date:
                                  </Typography>
                                  <Typography>
                                    {moment(
                                      wastedgeService.initial_serv_date
                                    ).format('LL')}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography noWrap variant="body2">
                                    Container Quantity:
                                  </Typography>
                                  <Typography>
                                    {wastedgeService.containers}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography noWrap variant="body2">
                                    Container Type:
                                  </Typography>
                                  <Typography>
                                    {wastedgeService.container_type}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography noWrap variant="body2">
                                    Route Cycle:
                                  </Typography>
                                  <Typography>{`Every ${
                                    RouteCycles(wastedgeService.route_cycle)
                                      .forEvery
                                  } ${
                                    RouteCycles(wastedgeService.route_cycle)
                                      .unit
                                  }`}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          );
                        }
                      })}
                    </Grid>
                  )}
                </Box>
              )}

              {!noServices && (
                <Box
                  sx={{
                    p: isDesktop ? '30px' : '15px',
                    gap: '15px',
                    background: grey[100],
                    borderRadius: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography>Risk Assessment Documents:</Typography>
                  {wastedgeDocs === null && (
                    <Box
                      sx={{
                        minHeight: '30vh',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: grey[400],
                      }}
                    >
                      <Typography variant="h4">
                        Loading your documents...
                      </Typography>
                    </Box>
                  )}
                  {wastedgeDocs && wastedgeDocs.length > 0 && (
                    <Box
                      sx={{
                        p: isDesktop ? '15px' : '10px',
                        maxHeight: '50vh',
                        background: grey[50],
                        borderRadius: '5px',
                        boxShadow: `0 1px 2px ${grey[900]}`,
                        overflow: 'auto',
                      }}
                    >
                      {wastedgeDocs.map((wastedgeDoc, index) => (
                        <Box key={wastedgeDoc.id}>
                          <Box
                            sx={{
                              p: isDesktop ? '15px' : '10px',
                              gap: '15px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                gap: '15px',
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <DocumentIcon sx={{ color: grey[500] }} />
                              <Box
                                sx={{
                                  maxWidth: isDesktop ? '40ch' : '20ch',
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <Typography noWrap>
                                  {wastedgeDoc.name}
                                </Typography>
                                <Typography noWrap variant="caption">
                                  {wastedgeDoc.accountID}
                                </Typography>
                                <Typography noWrap variant="caption">
                                  {wastedgeDoc.link}
                                </Typography>
                              </Box>
                            </Box>
                            <Button
                              onClick={() => {
                                window.open(wastedgeDoc.link);
                              }}
                              startIcon={isDesktop ? <DownloadIcon /> : null}
                              sx={{
                                color: accent800,
                                '&:hover': {
                                  color: accent900,
                                },
                              }}
                            >
                              {isDesktop ? 'Download' : <DownloadIcon />}
                            </Button>
                          </Box>
                          {index < wastedgeDocs.length - 1 && (
                            <Divider sx={{ my: isDesktop ? '15px' : '10px' }} />
                          )}
                        </Box>
                      ))}
                    </Box>
                  )}
                  {wastedgeDocs && wastedgeDocs.length < 1 && (
                    <Box
                      sx={{
                        minHeight: '10vh',
                        flexGrow: 1,
                        gap: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: grey[400],
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="h4">
                          No documents found.
                        </Typography>
                        <Typography variant="caption">
                          Contact us to request your documents.
                        </Typography>
                      </Box>
                      <Button
                        onClick={() => {
                          history.push('contact');
                          history.go(0);
                        }}
                        sx={{
                          color: accent800,
                          '&:hover': {
                            color: accent900,
                          },
                        }}
                      >
                        Contact us
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
              {!noServices && (
                <Box
                  sx={{
                    p: isDesktop ? '30px' : '15px',
                    gap: '15px',
                    background: grey[100],
                    borderRadius: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography>General Documents:</Typography>
                  <Box
                    sx={{
                      flexGrow: 1,
                      p: '15px',
                      gap: '15px',
                      background: grey[50],
                      boxShadow: `0 1px 2px ${grey[500]}`,
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        gap: '15px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {termsAndConditions.type === 'PDF' && (
                        <DocumentIcon sx={{ color: grey[700] }} />
                      )}
                      {termsAndConditions.type === 'IMG' && (
                        <ImageIcon sx={{ color: grey[700] }} />
                      )}
                      <Box>
                        <Typography>{termsAndConditions.name}</Typography>
                        <Typography variant="caption" sx={{ color: accent800 }}>
                          {termsAndConditions.type}
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      onClick={() => {
                        window.open(termsAndConditions.link);
                      }}
                      sx={{
                        color: accent800,
                        '&:hover': {
                          color: accent900,
                        },
                      }}
                    >
                      Open
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      p: '15px',
                      gap: '15px',
                      background: grey[50],
                      boxShadow: `0 1px 2px ${grey[500]}`,
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        flexGrow: 1,
                        gap: '15px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {wasterAPPTutorial.type === 'PDF' && (
                        <DocumentIcon sx={{ color: grey[700] }} />
                      )}
                      {wasterAPPTutorial.type === 'IMG' && (
                        <ImageIcon sx={{ color: grey[700] }} />
                      )}
                      <Box>
                        <Typography>{wasterAPPTutorial.name}</Typography>
                        <Typography variant="caption" sx={{ color: accent800 }}>
                          {wasterAPPTutorial.type}
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      onClick={() => {
                        window.open(wasterAPPTutorial.link);
                      }}
                      sx={{
                        color: accent800,
                        '&:hover': {
                          color: accent900,
                        },
                      }}
                    >
                      Open
                    </Button>
                  </Box>
                  <Box>
                    <Accordion>
                      <AccordionSummary expandIcon={<MoreIcon />}>
                        <Typography>Bin Safety</Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          maxHeight: '50vh',
                          overflow: 'auto',
                          px: '30px',
                          pb: '30px',
                          gap: '15px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {binSafetyDocs.map((binSafetyDoc, index) => (
                          <Box
                            sx={{
                              gap: '15px',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Box
                              sx={{
                                flexGrow: 1,
                                gap: '15px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  gap: '15px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {binSafetyDoc.type === 'PDF' && (
                                  <DocumentIcon sx={{ color: grey[700] }} />
                                )}
                                {binSafetyDoc.type === 'IMG' && (
                                  <ImageIcon sx={{ color: grey[700] }} />
                                )}
                                <Box>
                                  <Typography>{binSafetyDoc.name}</Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{ color: accent800 }}
                                  >
                                    {binSafetyDoc.type}
                                  </Typography>
                                </Box>
                              </Box>
                              <Button
                                onClick={() => {
                                  window.open(binSafetyDoc.link);
                                }}
                                sx={{
                                  color: accent800,
                                  '&:hover': {
                                    color: accent900,
                                  },
                                }}
                              >
                                Open
                              </Button>
                            </Box>
                            {index < binSafetyDocs.length - 1 && <Divider />}
                          </Box>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box>
                    <Accordion>
                      <AccordionSummary expandIcon={<MoreIcon />}>
                        <Typography>Recycling Flowcharts</Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          maxHeight: '50vh',
                          overflow: 'auto',
                          px: '30px',
                          pb: '30px',
                          gap: '15px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {recyclingFlowcharts.map(
                          (recyclingFlowchart, index) => (
                            <Box
                              sx={{
                                gap: '15px',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  gap: '15px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Box
                                  sx={{
                                    flexGrow: 1,
                                    gap: '15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {recyclingFlowchart.type === 'PDF' && (
                                    <DocumentIcon sx={{ color: grey[700] }} />
                                  )}
                                  {recyclingFlowchart.type === 'IMG' && (
                                    <ImageIcon sx={{ color: grey[700] }} />
                                  )}
                                  <Box>
                                    <Typography>
                                      {recyclingFlowchart.name}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      sx={{ color: accent800 }}
                                    >
                                      {recyclingFlowchart.type}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Button
                                  onClick={() => {
                                    window.open(recyclingFlowchart.link);
                                  }}
                                  sx={{
                                    color: accent800,
                                    '&:hover': {
                                      color: accent900,
                                    },
                                  }}
                                >
                                  Open
                                </Button>
                              </Box>
                              {index < recyclingFlowcharts.length - 1 && (
                                <Divider />
                              )}
                            </Box>
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Box>
                    <Accordion>
                      <AccordionSummary expandIcon={<MoreIcon />}>
                        <Typography>Bin Posters and Stickers</Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          maxHeight: '50vh',
                          overflow: 'auto',
                          px: '30px',
                          pb: '30px',
                          gap: '15px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {binPostersAndStickers.map(
                          (binPostersAndSticker, index) => (
                            <Box
                              sx={{
                                gap: '15px',
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  gap: '15px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Box
                                  sx={{
                                    flexGrow: 1,
                                    gap: '15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {binPostersAndSticker.type === 'PDF' && (
                                    <DocumentIcon sx={{ color: grey[700] }} />
                                  )}
                                  {binPostersAndSticker.type === 'IMG' && (
                                    <ImageIcon sx={{ color: grey[700] }} />
                                  )}
                                  <Box>
                                    <Typography>
                                      {binPostersAndSticker.name}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      sx={{ color: accent800 }}
                                    >
                                      {binPostersAndSticker.type}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Button
                                  onClick={() => {
                                    window.open(binPostersAndSticker.link);
                                  }}
                                  sx={{
                                    color: accent800,
                                    '&:hover': {
                                      color: accent900,
                                    },
                                  }}
                                >
                                  Open
                                </Button>
                              </Box>
                              {index < binPostersAndStickers.length - 1 && (
                                <Divider />
                              )}
                            </Box>
                          )
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Box>
              )}
            </Container>
          </Box>
        )}
      </Box>
      {isDesktop ? <Footer /> : null}
    </Box>
  );
}
